import React, { ReactNode } from "react";
import GuideImage from "../Guide/GuideImage";

interface Props {
  title?: string;
  image?: string;
  children?: ReactNode;
}

function LoginForm({ title, image, children }: Props) {
  return (
    <section>
      <div className="grid grid-cols-1 lg:grid-cols-3 items-center gap-y-10">
        <div className="lg:col-span-2">
          {image && <GuideImage image={image} />}
        </div>
        <div className="lg:col-span-1">{children}</div>
      </div>
    </section>
  );
}

export default LoginForm;
