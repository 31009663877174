"use client";
import ModalsButton from "@/components/Buttons/ModalsButton";
import LoadingViews from "@/components/Skeleton/LoadingViews";
import LoginForm from "@/components/Login/LoginForm";
import MainScreen from "@/components/MainScreen";
import LoginBody from "@/components/Modals/login/LoginBody";
import LoginFooter from "@/components/Modals/login/LoginFooter";
import LinksNavigation from "@/constants/navigation";
import React, { Suspense } from "react";

function Login() {
  const links = LinksNavigation();
  return (
    <>
      <Suspense fallback={<LoadingViews />}>
        <MainScreen>
          <main className="max-width-content py-10 lg:py-16">
            <LoginForm image="/loginImg.svg">
              <LoginBody />

              <LoginFooter onClick={links.handleClickRegister} />
            </LoginForm>
          </main>
        </MainScreen>
      </Suspense>
    </>
  );
}

export default Login;
